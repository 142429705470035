import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { formatCurrency } from '../../helpers/format';
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { isEmpty } from 'lodash';
import { getStores } from '../../slices/thunks';
import { useDispatch } from 'react-redux';
import { useBreakpoints } from '../../Components/Hooks/BreakpointHooks';

const ThirdTab = ({ resume, t, updateOrderForm }) => {
  const dispatch = useDispatch();
  const { isMd, isXs, isSm } = useBreakpoints();

  const [stores, setStores] = useState([]);
  const [nameStoreFilter, setNameStoreFilter] = useState('');
  const getUserStores = async () => {
    const params = { take: 10 };

    if (!isEmpty(nameStoreFilter)) {
      params.name = nameStoreFilter;
    }

    const action = await dispatch(getStores(params));

    if (!action.payload) {
      return;
    }

    setStores(
      action.payload.data.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    );
  };

  useEffect(() => {
    getUserStores();
  }, []);

  const isInvalid = (form, field) => {
    return form.touched[field] && form.errors[field]
  }


  return (
    <React.Fragment>
      <div className='d-flex flex-column px-4'>
        <Row>
          <div className='d-flex align-items-center'>
            <i
              className='ri-checkbox-circle-line label-icon text-success px-2'
              style={{ fontSize: 'calc(2.33125rem + 0.475vw)' }}
            ></i>
            <div className='ml-2'>
              <h4 className='text-success fw-bold mb-0'>
                {t('sale-completed')}
              </h4>
              <h6 className='text-muted'>
                {t('check-details-below')}
              </h6>
            </div>
          </div>
        </Row>
        <Row className='mt-3'>
          <Col
            xs={12}
            lg={6}
            className={`${
              isMd || isXs || isSm ? 'border-bottom' : 'border-end'
            } border-gray px-5`}
          >
            <Row>
              <Col xs={12} md={6}>
                <Card className='text-center'>
                  <CardHeader className='bg-success text-white text-uppercase'>
                    {t('used-balance')}
                  </CardHeader>
                  <CardBody className='m-2'>
                    <h4 className='fw-bold'>
                      {formatCurrency(resume?.rescue || 0)}
                    </h4>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card className='text-center'>
                  <CardHeader className='bg-success text-white text-uppercase'>
                    {t('discount-percentage')}
                  </CardHeader>
                  <CardBody className='m-2'>
                    <h4 className='fw-bold'>{resume?.percentage || '0'}%</h4>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <h6 className='mt-3 fw-bold'>{t('other-details')}</h6>
              <Table className='align-middle table-nowrap'>
                <tbody className='border'>
                  <tr>
                    <td className='border text-dark'>{t('order-total')}</td>
                    <td className='border'>
                      {resume?.order?.total >= 0
                        ? formatCurrency(resume.order.total)
                        : ''}
                    </td>
                  </tr>
                  <tr>
                    <td className='border text-dark'>{t('client')}</td>
                    <td className='border'>{resume?.customer?.name || ''}</td>
                  </tr>
                  <tr>
                    <td className='border text-dark'>{t('accumulation-amount')}</td>
                    <td className='border'>
                      {resume?.order?.earned_cashback >= 0
                        ? formatCurrency(resume.order.earned_cashback)
                        : ''}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Col>
          <Col xs={12} lg={6} className='px-5'>
            <h4 className='py-3'>{t('additional-information')}</h4>
            <Form className='tablelist-form'>
              <Row className='justify-content-center'>
                <Col xs={12}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row className='mb-3'>
                        <Label
                          htmlFor='manual-order-update-seller-field'
                          className='form-label text-muted fw-bold'
                          xs={3}
                        >
                          {t('order-seller')}
                        </Label>
                        <Col xs={9}>
                          <Input
                            name='seller'
                            id='manuel-order-update-seller-field'
                            className='form-control'
                            placeholder={t('document-placeholder')}
                            type='text'
                            disabled
                            onChange={updateOrderForm.handleChange}
                            value={updateOrderForm.values.seller}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row className='mb-3'>
                        <Label
                          htmlFor='manual-order-update-store-field'
                          className='form-label text-muted fw-bold'
                          xs={3}
                        >
                          {t('store')}
                        </Label>
                        <Col xs={9}>
                          <CreatableSelect
                            isClearable
                            placeholder={t('select-placeholder')}
                            name='store'
                            id='manual-order-update-store-field'
                            formatCreateLabel={(e) => `Criar Loja: ${e}`}
                            className='form-group w-100'
                            value={updateOrderForm.values.store}
                            onChange={(e) =>
                              updateOrderForm.setFieldValue('store', e)
                            }
                            onInputChange={(e) => setNameStoreFilter(e)}
                            options={stores}
                          />                          
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row className='mb-3'>
                        <Label
                          htmlFor='manual-order-update-ticket-field'
                          className='form-label text-muted fw-bold'
                          xs={3}
                        >
                           {t('ticket')}
                        </Label>
                        <Col xs={9}>
                          <Input
                            name='ticket'
                            id='manual-order-update-ticket-field'
                            className='form-control'
                            type='text'
                            onChange={updateOrderForm.handleChange}
                            value={updateOrderForm.values.ticket}
                            invalid={isInvalid(updateOrderForm, 'ticket')}
                          />       
                          {isInvalid(updateOrderForm, 'ticket') && (
                              <FormFeedback type='invalid'>
                                {updateOrderForm.errors.ticket}
                              </FormFeedback>
                           )}                   
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(ThirdTab);
