import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getIncentiveGroups as getIncentiveGroupsApi,
  getAllIncentiveGroups as getAllIncentiveGroupsApi,
  getIncentiveGroup as getIncentiveGroupApi,
  createIncentiveGroup as createIncentiveGroupApi,
  createIncentive as createIncentiveApi,
  batchImportIncentiveApi,
  deleteGroup as deleteGroupApi,
  cancelIncentive as cancelIncentiveApi,
  getIncentiveGroupUsers as getIncentiveGroupUsersApi,
  getSmartIncentives as getSmartIncentivesApi,
  putSmartIncentives as putSmartIncentivesApi,
  getIncentiveDetailsFilters as getIncentiveDetailsFiltersApi,
} from '../../helpers/bff_helper';
import { toast } from 'react-toastify';

export const getIncentiveGroups = createAsyncThunk(
  'incentives/getGroups',
  async (params) => {
    try {
      const response = getIncentiveGroupsApi(params);

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getIncentiveGroup = createAsyncThunk(
  'incentives/getGroupById',
  async ({ groupId, ...params }) => {
    try {
      params.order = 'desc';
      return getIncentiveGroupApi(groupId, params);
    } catch (error) {
      return error;
    }
  }
);

export const getIncentiveGroupUsers = createAsyncThunk(
  'incentives/getGroupUsers',
  async (id) => {
    try {
      return getIncentiveGroupUsersApi(id);
    } catch (error) {
      return error;
    }
  }
);

export const getAllIncentiveGroups = createAsyncThunk(
  'incentives/getAllGroups',
  async (params) => {
    try {
      const response = getAllIncentiveGroupsApi(params);

      return response;
    } catch (error) {
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const createIncentiveGroup = createAsyncThunk(
  'incentives/createGroup',
  async ({ group, successMessage, errorMessage }) => {
    try {
      const response = createIncentiveGroupApi(group);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const createIncentive = createAsyncThunk(
  'incentives/createIncentive',
  async ({ incentive, successMessage, errorMessage }) => {
    try {
      const response = createIncentiveApi(incentive);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const batchImportIncentive = createAsyncThunk(
  'incentives/batchImportIncentive',
  async (params) => {
    try {
      const response = await batchImportIncentiveApi(params);

      toast.success(params?.successMessage);

      return JSON.stringify(response);
    } catch (error) {
      toast.error(params?.errorMessage);
      return error;
    }
  }
);

export const deleteGroup = createAsyncThunk(
  'incentives/deleteGroup',
  async ({ groupId, successMessage, errorMessage }) => {
    try {
      const response = await deleteGroupApi(groupId);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const cancelIncentive = createAsyncThunk(
  'incentives/cancelIncentive',
  async ({
    id,
    successMessage,
    errorMessage,
    incentiveUsedErrorMessage,
    incentiveExpiredErrorMessage,
  }) => {
    try {
      const response = await cancelIncentiveApi(id);
      toast.success(successMessage, { autoClose: 5000 });

      return response;
    } catch (error) {
      if (error?.id === 'INC-005') {
        toast.error(incentiveExpiredErrorMessage, { autoClose: 5000 });
        return {
          error: true,
          message: error,
        };
      }

      if (error?.id === 'INC-006') {
        toast.error(incentiveUsedErrorMessage, { autoClose: 5000 });
        return {
          error: true,
          message: error,
        };
      }

      toast.error(errorMessage, { autoClose: 5000 });
      return {
        error: true,
        message: error,
      };
    }
  }
);

export const getSmartIncentives = createAsyncThunk(
  'incentives/getSmartIncentives',
  async () => {
    try {
      return getSmartIncentivesApi();
    } catch (error) {
      return error;
    }
  }
);

export const putSmartIncentives = createAsyncThunk(
  'incentives/putSmartIncentives',
  async ({ id, ...body }) => {
    try {
      return putSmartIncentivesApi(id, body);
    } catch (error) {
      return error;
    }
  }
);

export const getIncentiveDetailsFilters = createAsyncThunk(
  'incentives/details/filters',
  async () => {
    try {
      return getIncentiveDetailsFiltersApi();
    } catch (error) {
      return error;
    }
  }
);
