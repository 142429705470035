import { useEffect, useState, useMemo, useReducer } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

import TableContainer from "../../Components/Common/TableContainer";
import Filter, { getFilters } from "../../Components/Common/Filter";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getProducts } from "../../slices/catalogs/thunk";

import { Can } from "../../helpers/casl";
import Search from "../../Components/Common/Search";
import EmptyList from "../../Components/Common/EmptyList";
import CatalogImporter from "./components/CatalogImporter";
import CatalogRemoveItem from "./components/CatalogRemoveItem";
import useQsParams from "../../Components/Hooks/QueryString";
import { formatDate } from "../../helpers/format";

const GROUP = "Ferramentas";
const TITLE = "Lista de produtos cadastrados";

const Catalog = (props) => {
  document.title = `${TITLE} | Opencashback`;
  const dispatch = useDispatch();

  const { qs, searchParams, setSearchParams } = useQsParams();
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [page, setPage] = useState(1);

  const [modals, dispatchModal] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "toggleImportModal":
          return { ...state, isImportModalOpened: !state?.isImportModalOpened };
        case "toggleDeleteModal":
          return { ...state, isDeleteModalOpened: !state?.isDeleteModalOpened };
        default:
          throw Error("Unknown modal action.");
      }
    },
    {
      isImportModalOpened: false,
      isDeleteModalOpened: false,
    }
  );

  const catalogState = createSelector(
    (state) => state.Catalog.catalog,
    (products) => products
  );

  const loadingState = createSelector(
    (state) => state.Catalog.isLoading,
    (isLoading) => isLoading
  );

  const metaState = createSelector(
    (state) => state.Catalog.meta,
    (meta) => meta
  );

  const meta = useSelector(metaState);
  const catalog = useSelector(catalogState);
  const isLoading = useSelector(loadingState);
  const noResults = !isLoading && !catalog?.products?.length;

  useEffect(() => {
    getData();
  }, [page, searchParams.toString()]);

  const filters = [
    {
      field: "category",
      name: props.t("category"),
      type: "text",
      values: [],
    },
    {
      type: "date",
      field: "created_at",
      name: props.t("created_at"),
      options: { maxDate: false },
      values: [],
    },
  ];

  const getData = () => {
    const order = "desc";
    let params = { page, order };

    const appliedFilters = getFilters(filters, [
      { field: "external_id", type: "eq" },
    ]);

    const hasSearchParam = searchParams.get("name");
    if (hasSearchParam) {
      params = { ...params, name: hasSearchParam };
    }

    if (!isEmpty(appliedFilters)) {
      params.filters = appliedFilters;
    }

    dispatch(getProducts(params));
  };

  const columns = useMemo(
    () => [
      {
        Header: props.t("catalog-table-sku_id"),
        accessor: "external_id",
        filterable: true,
      },
      {
        Header: props.t("catalog-table-sku_name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: props.t("catalog-table-category_name"),
        accessor: "category.name",
        filterable: false,
      },
      {
        Header: props.t("catalog-table-category_id"),
        accessor: "category.external_id",
        filterable: false,
      },
      {
        Header: props.t("created-at"),
        accessor: "created_at",
        filterable: false,
        Cell: (props) =>
          formatDate(props.row.original.created_at, "DD/MM/YYYY"),
        reportProps: {
          formatter: {
            type: "date",
            properties: { tz: "America/Sao_Paulo", format: "DD/MM/YYYY" },
          },
        },
      },
      {
        Header: props.t("actions"),
        Cell: (cellProps) => (
          <Can I='manage' a='catalogs'>
            <Link
              title='Remover item'
              className='delete-item-btn'
              onClick={() => {
                setSelectedProduct(cellProps.row.original);
                dispatchModal({ type: "toggleDeleteModal" });
              }}
            >
              <i className='ri-delete-bin-line align-bottom text-muted' />
            </Link>
          </Can>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title={TITLE} pageTitle={GROUP} />

          <Row>
            <Col lg={12}>
              <Card id='leadsList'>
                <CardHeader className='border-0'>
                  <Row className='g-4 align-items-center'>
                    <Col xs={12} md={6} xl={2} lg={6}>
                      <Search
                        placeholder={props.t("code")}
                        value={searchParams?.get("external_id")}
                        onChange={(c) => {
                          if (!c?.length) setSearchParams("external_id", "");
                        }}
                        onSubmit={({ id }) => {
                          setPage(1);
                          setSearchParams("external_id", id ?? "");
                        }}
                      />
                    </Col>

                    <Col xs={12} md={6} xl={2} lg={6}>
                      <Search
                        placeholder={props.t("product")}
                        value={searchParams?.get("name")}
                        onChange={(c) => {
                          if (!c?.length) setSearchParams("name", "");
                        }}
                        onSubmit={({ id }) => {
                          setPage(1);
                          setSearchParams("name", id ?? "");
                        }}
                      />
                    </Col>
                    <Col sm={1}>
                      {isLoading && <Spinner color='secondary' />}
                    </Col>

                    <div className='col-sm-auto ms-auto'>
                      <div className='hstack gap-2'>
                        <button
                          type='button'
                          className='btn btn-primary'
                          onClick={() => setSearchParams("showFilter", true)}
                        >
                          <i className='ri-filter-3-line align-bottom me-1' />
                          {props.t("filters")}
                        </button>

                        <Can I='create' a='catalogs'>
                          <button
                            type='button'
                            id='create-btn'
                            className='hideButton btn btn-secondary add-btn'
                            onClick={() =>
                              dispatchModal({ type: "toggleImportModal" })
                            }
                          >
                            <i className='ri-file-upload-line align-bottom me-1' />
                            {props.t("catalog-import-button")}
                          </button>
                        </Can>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody className='pt-0'>
                  {noResults && !qs.toString() ? (
                    <EmptyList
                      body={props.t("catalog-empty-body")}
                      button={{
                        icon: "ri-file-upload-line",
                        label: props.t("catalog-import-button"),
                        onClick: () =>
                          dispatchModal({ type: "toggleImportModal" }),
                      }}
                    />
                  ) : noResults && qs?.toString() ? (
                    <EmptyList
                      hideIcon
                      heading={props.t("search-empty-heading")}
                      body={props.t("search-empty-body")}
                    />
                  ) : (
                    <TableContainer
                      isLoading={isLoading}
                      columns={columns}
                      data={catalog?.products || []}
                      activePage={page}
                      pageSize={meta?.take || 10}
                      totalPages={meta?.pages || 0}
                      count={meta?.total || 0}
                      setPage={setPage}
                      className='custom-header-css'
                      divClass='table-responsive table-card mb-0'
                      tableClass='align-middle table-nowrap'
                      theadClass='table-light'
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Filter
        values={filters.map(({ field }) => ({ field }))}
        filters={filters}
      />

      <CatalogImporter
        isOpen={modals.isImportModalOpened}
        toggle={() => dispatchModal({ type: "toggleImportModal" })}
        onSuccess={getData}
      />

      <CatalogRemoveItem
        isOpen={modals.isDeleteModalOpened}
        toggle={() => dispatchModal({ type: "toggleDeleteModal" })}
        product={selectedProduct}
        onSuccess={getData}
      />

      <ToastContainer position='top-center' closeButton={false} limit={1} />
    </>
  );
};

export default withTranslation()(Catalog);
