import { withTranslation } from "react-i18next";
import { Label } from "reactstrap";
import moment from "moment";

import useQsParams from "../../Hooks/QueryString";
import RangeDatePicker from "../RangeDatePicker";

function DateFilter({ item, t }) {
  const { qs, setSearchParams, setSearchParamsAsObject } = useQsParams();
  const field = qs.get(item.field)?.split(",");
  let value = [];

  if (field?.length) {
    value = [
      moment.unix(field[0]).format("D/M/YY"),
      moment.unix(field[1]).format("D/M/YY"),
    ];
  }

  return (
    <div className='mb-4 filter-item'>
      <Label className='form-label text-muted text-uppercase fw-semibold mb-3'>
        {item.name}
      </Label>

      <RangeDatePicker
        options={{ ...item?.options }}
        name={item.field}
        value={value}
        placeholder={t("select-date")}
        onClear={() => setSearchParams(item.field, "")}
        onChange={(v) => {
          if (v[0] && v[1]) {
            const d1 = moment(v[0]).unix();
            const d2 = moment(v[1]).unix();

            setSearchParamsAsObject({
              [item.field]: !d2 ? d1 : `${d1},${d2}`,
              page: 1,
            });
          }
        }}
      />
    </div>
  );
}

export default withTranslation()(DateFilter);
