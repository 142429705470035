import { withTranslation } from "react-i18next";
import { Label } from "reactstrap";
import Select from "react-select";

import useQsParams from "../../Hooks/QueryString";

function SelectFilter({ key, item, t }) {
  const { qs, setSearchParamsAsObject } = useQsParams();
  const field = qs.get(item.field);

  return (
    <div className='mb-4 filter-item'>
      <Label
        htmlFor={`select-${key}`}
        className='form-label text-muted text-uppercase fw-semibold mb-3'
      >
        {item.name}
      </Label>

      <Select
        isClearable
        isSearchable
        noOptionsMessage={() => "Nenhum item disponível"}
        options={[...(item?.values ?? [])]}
        value={item?.values?.find((v) => v?.value === field)}
        onChange={(e) =>
          setSearchParamsAsObject({
            [item.field]: e?.value,
            page: 1,
          })
        }
        placeholder={t("select-placeholder")}
        id={`select-${key}`}
        className='mb-0'
      />
    </div>
  );
}

export default withTranslation()(SelectFilter);
