import { combineReducers } from 'redux';

// Front
import LayoutReducer from './layouts/reducer';

// Authentication
import LoginReducer from './auth/login/reducer';
import AccountReducer from './auth/register/reducer';
import ProfileReducer from './auth/profile/reducer';
import OrderReducer from './orders/reducer';
import CustomerReducer from './customers/reducer';
import StrategyReducer from './strategies/reducer';
import CatalogReducer from './catalogs/reducer';
import IncentiveReducer from './incentives/reducer';
import UserReducer from './users/reducer';
import ResultReducer from './results/reducer';
import Communication from './communication/reducer';
import IndicatorsReducer from './indicators/reducer';
import ReportsReducer from './reports/reducer';
import LeadCapture from './lead-capture/reducer';
import MyWallet from './my-wallet/reducer';
import Stores from "./stores/reducer";
import Integrations from './integrations/reducer'
import GeneralSettings from './general-settings/reducer';

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  Order: OrderReducer,
  Customer: CustomerReducer,
  Strategy: StrategyReducer,
  Catalog: CatalogReducer,
  Incentive: IncentiveReducer,
  User: UserReducer,
  Result: ResultReducer,
  Communication,
  Indicators: IndicatorsReducer,
  Reports: ReportsReducer,
  LeadCapture,
  MyWallet,
  Stores,
  Integrations,
  GeneralSettings,
});

export default rootReducer;
