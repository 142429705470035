import { useState } from "react";
import { Col, Collapse, Nav, NavItem, NavLink, Row } from "reactstrap";
import useQsParams from "../Hooks/QueryString";

const PresetsFilter = ({ items, header = true, overwriteFilters = true }) => {
  const { searchParams, setSearchParamsAsObject } = useQsParams();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      {header ? (
        <p className='filter-header-collapsible' data-opened={isOpen}>
          <span onClick={() => setIsOpen(!isOpen)}>Filtros pré-definidos</span>
        </p>
      ) : null}

      <Row>
        <Col>
          <Collapse isOpen={isOpen}>
            <Nav
              className='pill gap-3 px-3 overflow-auto'
              style={{
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
                paddingBottom: 15,
              }}
            >
              {items.map((item, key) => {
                const isActive = searchParams.get("preset") === item.key;

                const onClick = () => {
                  const payload = { preset: item?.key, ...item?.value };

                  const fieldsToRemove = items?.reduce((carret, item) => {
                    const keysArr = Object.keys(item?.value);
                    const keysMap = keysArr.map((k) => ({ [k]: "" }));
                    const empties = Object.assign({}, ...keysMap);
                    return { ...carret, ...empties };
                  }, {});

                  const params = !isActive
                    ? { ...fieldsToRemove, ...payload }
                    : { ...fieldsToRemove, preset: "" };

                  setSearchParamsAsObject({ ...params, page: 1 });
                };

                return (
                  <NavItem key={key}>
                    <NavLink
                      active={isActive}
                      onClick={onClick}
                      className='px-3 py-2'
                      href='#'
                    >
                      {item.label}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default PresetsFilter;
