import { withTranslation } from "react-i18next"

const EmptyListState = ({ heading, body, button, hideIcon = false, t }) => (
  <div className="CSVImporter_ImporterFrame p-4 text-center">
    {!hideIcon && (
      <i className="ri-windy-line text-light-emphasis align-bottom me-1 fs-48" />
    )}

    <h3 className="fs-24 mt-2">
      {heading || t("table-content-empty")}
    </h3>

    {body && (
      <p className="fs-16">{body}</p>
    )}

    {button?.label && button?.onClick && (
      <button
        type="button"
        className="hideButton btn btn-secondary add-btn mt-2"
        id="call-to-action"
        onClick={button?.onClick}
      >
        {button?.icon && (<i className={`align-bottom me-1 ${button?.icon}`} /> )}
        {button.label}
      </button>
    )}
  </div>
)

export default withTranslation()(EmptyListState)